import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeroBanner from '../../components/sections/hero-banner';
import { Route } from '../../models/site-links';
import Feature from '../../components/sections/industry/feature-block';
import TripleFeature from '../../components/sections/triple-feature';
import ConnectWithUs from '../../components/sections/connect-with-us';
import { getDemoLink } from '../../services/demo-selector';
import WhyPatronscan from '../../components/sections/why-patronscan';
import './dispensaries.css';
import SEO from '../../components/seo';

const Dispensaries = () => {
  const data = useStaticQuery(graphql`
    query {
      countertop: file(relativePath: { eq: "product-card/countertop-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      challengeLight: file(relativePath: { eq: "dispensaries/challengeQuestionsLight.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyImage: file(relativePath: { eq: "vape/privacy.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      securityAlerts: file(relativePath: { eq: "icons/securityAlerts.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flaggedPatrons: file(relativePath: { eq: "icons/flaggedPatrons.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pulse: file(relativePath: { eq: "icons/pulse.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDBanner: file(relativePath: { eq: "hero-images/fakeID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      antiLooping: file(relativePath: { eq: "dispensaries/looping.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marketingInsight: file(relativePath: { eq: "property-management/marketing-insights.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      passports: file(relativePath: { eq: "fake-id-scanner/passportscan.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDAlert: file(relativePath: { eq: "entertainment/fakeID_alert.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO
        title="Patronscan | ID Scanners for Dispensaries"
        description="Spot minors, catch fakes and identify resellers using secure and private ID scanners. Prevent the sale to minors with retail cannabis’ choice for ID scanners."
      />
      <HeroBanner
        heroImage={data.fakeIDBanner.childImageSharp.fluid}
        heading="Trusted ID Verification for Dispensaries"
        subHeading="Prevent youth uptake with the world's best ID scanners"
        videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="DispensariesHero:BookDemo"
      />
      <Feature
        rightAlign
        image={data.fakeIDAlert.childImageSharp.fluid}
        heading="Trusted fake ID"
        headingThin="detection"
        subHeading="Secure and private, Patronscan's leading fake ID detection keeps marijuana out of the hands of minors."
        description="Patronscan has been the best fake ID scanner available in North America for years. OCR (Optical Character Recognition) technology is used to read the face of the ID, just like a human does, in order to improve fake ID detection."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId={`DispensariesFeatureTrustedFakeID:ContactUs`}
      />
      <Feature
        image={data.passports.childImageSharp.fluid}
        heading="Validate over 4,500 "
        headingThin="different types of IDs"
        subHeading="Scan passports, driver's licenses, military cards and status cards from just about anywhere in the world."
        description="Verify age, expiry and authenticity of any government issued ID in North America within seconds. Scan tourist IDs anywhere from a UK driver's license to an Australian passport."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId={`DispensariesFeature4500IDs:BookDemo`}
      />
      <Feature
        rightAlign
        image={data.privacyImage.childImageSharp.fluid}
        heading="Secure and private"
        headingThin="data management"
        subHeading="Patrons trust you to keep personal information private. Trust us to keep it that way."
        description="Secure access, automatic deletion and top-tier storage facilities are just some of the ways we protect data while helping keep your dispensary compliant with local privacy laws."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId={`DispensariesFeatureSecurePrivate:ContactUs`}
      />
      <Feature
        image={data.challengeLight.childImageSharp.fluid}
        heading="Prevent ID sharing"
        headingThin="with challenge questions"
        subHeading="Challenge questions are a great way to test a customer if you suspect they are using someone else’s ID."
        description="Patronscan provides challenge questions for every scan, so it is easy for your staff to ask a question or two and observe the body language of the customer."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId={`DispensariesFeatureChallengeQuestions:BookDemo`}
      />
      <Feature
        rightAlign
        image={data.antiLooping.childImageSharp.fluid}
        heading="Curb looping with"
        headingThin="recurrent visit alerts"
        subHeading="Catch customers 'looping' back to your business trying to circumnavigate daily purchase limits."
        description="Track daily purchases to ensure you can sell up to a customer's daily limit without going over. Receive alerts for customers frequenting your location(s) more than once a day."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId={`DispensariesFeatureCurbLooping:ContactUs`}
      />
      <Feature
        image={data.marketingInsight.childImageSharp.fluid}
        heading="Demographic reports"
        headingThin="with valuable insights"
        subHeading="Daily reports give you valuable information about your business and your customers."
        description="Identify trends, spot opportunities, and keep an eye on your customer base with reports that arrive daily in your email. Optimize your marketing and operation plans using key data."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId={`DispensariesFeatureMarketingInsights:GetPricing`}
      />
      <TripleFeature
        firstFeatureIcon={data.flaggedPatrons.childImageSharp.fluid}
        secondFeatureIcon={data.pulse.childImageSharp.fluid}
        thirdFeatureIcon={data.securityAlerts.childImageSharp.fluid}
        firstHeadingText="Shared Flagged Patron List"
        secondHeadingText="Live SMS Stats"
        thirdHeadingText="Security Notifications"
        firstDescriptionText="Spot individuals that other marijuana dispensaries have flagged for causing harm. Make an informed decision about whether to allow this person into your establishment."
        secondDescriptionText="Live stats will tell you exactly how many customers have purchased from your business, gender distribution, and average age. Keep a pulse on every establishment you manage."
        thirdDescriptionText="Receive security alerts to your phone when a fake ID is scanned or when a situation requires the intervention of security personnel."
      />
      <WhyPatronscan />
      <div className="dispensaries-connect-spacing">
        <ConnectWithUs
          buttonId="DispensariesConnect"
          promptText="Talk to one of our representatives today for pricing information or to book a demo and see how Patronscan works to keep legal cannabis out of the hands of minors."
        />
      </div>
    </div>
  );
};

export default Dispensaries;
